<template>
  <div
    class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-right hp-border-color-black-40 hp-border-color-dark-80"
  >
    <div class="hp-sidebar-container">
      <div class="hp-sidebar-header-menu">
        <sidebar-header
          v-if="header"
          :collapsed="storeSidebarCollapsed"
          @sidebarCollapsedProps="sidebarCollapsed"
        />

        <ul>
          <sidebar-item
            v-for="(item, index) in sidebarItems"
            :key="index"
            :collapsed="storeSidebarCollapsed"
            :item="item"
          />
        </ul>
      </div>

      <sidebar-footer :collapsed="storeSidebarCollapsed" />
    </div>
  </div>
</template>

<script>
import SidebarJs from '@/utils/sidebar-collapse-dropdown.js'
import SidebarHeader from './header'
import SidebarItem from './item'
import SidebarFooter from './footer'
import getNavigations from '../../../navigation'

export default {
  components: {
    SidebarHeader,
    SidebarItem,
    SidebarFooter,
  },
  props: {
    header: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      sidebarItems: [],
      userInfo: JSON.parse(window.localStorage.getItem('userInfo')),
    }
  },
  computed: {
    storeSidebarCollapsed() {
      return this.$store.state.themeConfig.sidebarCollapsed
    },
  },
  watch: {
    userInfo: {
      handler() {
        this.updateSidebarItems()
      },
      deep: true,
    },
    '$store.state.themeConfig.direction': {
      handler() {
        this.getDirection()
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateSidebarItems();
    console.log(this.sidebarItems)
  },
  methods: {
    sidebarCollapsed(check) {
      if (check) {
        this.$store.state.themeConfig.sidebarCollapsed = true
      } else {
        this.$store.state.themeConfig.sidebarCollapsed = false
      }

      document.body.classList.toggle('collapsed-active')
    },

    getDirection() {
      SidebarJs.sidebarCollapseDropdown()
    },
    updateSidebarItems() {
      this.sidebarItems = getNavigations(this.userInfo?.role)
    },
  },
}
</script>
