<template>
  <header>
    <b-row class="w-100 m-0">
      <b-col class="px-0">
        <b-row align-v="center" align-h="between" class="w-100 position-relative">
          <b-col class="w-auto hp-flex-none hp-mobile-sidebar-button ml-6 ml-lg-0 mr-24 px-0">
            <b-button variant="text"
                      class="btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition"
                      @click="mobileSidebarShow = !mobileSidebarShow">
              <i class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1" style="font-size: 24px"></i>
            </b-button>
          </b-col>

          <header-text v-if="isAnalyticsRoute" />
          <header-user />

        </b-row>
      </b-col>
    </b-row>


    <b-sidebar id="mobile-sidebar" backdrop-variant="black-100" backdrop no-close-on-backdrop shadow
               bg-variant="black-20"
               class="hp-mobile-sidebar custom-sidebar" body-class="p-0 d-flex flex-column" no-header-close no-header width="256px"
               :visible="mobileSidebarShow">
      <div class="d-flex align-items-center justify-content-between pt-16 px-16">
        <logo/>

        <div class="w-auto px-0 mt-4">
          <div @click="mobileSidebarShow = false">
            <i class="ri-close-fill lh-1 hp-text-color-black-80" style="font-size: 24px"></i>
          </div>
        </div>
      </div>

      <sidebar :header="false"/>
    </b-sidebar>
  </header>
</template>

<script>
import {BCol, BRow, BButton, BSidebar, BLink} from "bootstrap-vue";

import HeaderText from "./HeaderText.vue";
import HeaderUser from "./HeaderUser.vue";
import Sidebar from "../menu/Sidebar.vue";
import Logo from "../logo";

export default {
  data() {
    return {
      mobileSidebarShow: false,
      theme: this.$store.state.themeConfig.theme,
      version: this.$store.state.themeConfig.version,
    };
  },
  components: {
    BCol,
    BRow,
    BButton,
    BSidebar,
    BLink,
    HeaderText,
    HeaderUser,
    Sidebar,
    Logo,
  },
  computed: {
    isAnalyticsRoute() {
      return this.$route.path === '/dashboards/analytics';
    }
  }
};
</script>

<style scoped>
.custom-sidebar {
  z-index: 1050 !important;
}
</style>